body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    */
    font-family: 'Helvetica', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2d2e32;
    background-color: #f1f4f9;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Helvetica', serif; /*Old Standard TT*/
}

.section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section-padding-t {
    padding-top: 50px;
}

.w-100 {
    width: 100%;
}

.viedo-carousel .slick-center.slick-current {
    transform: scaleX(1.4) scaleY(1.3);
    z-index: 1000000;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
}

.viedo-carousel .slick-slide {
    transition: 1s;
}

.slick-next:before, .slick-prev:before {
    color: #bf9410;
}

@media screen and (min-width: 800px) {
    .viedo-carousel .slick-list {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
}

@media screen and (min-width: 300px) and (max-width: 799px) {
    .viedo-carousel .slick-current {
        transform: scale(1);
    }

    .viedo-carousel .slick-list {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
}

.playButtonContainer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: none;
    transition: 0.5s;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.15);
}

    .playButtonContainer .MuiSvgIcon-root {
        font-size: 5rem;
        transform: scaleX(0.95);
        cursor: pointer;
    }

.slick-current .playButtonContainer {
    display: flex;
}

.slick-dots {
    bottom: -25px;
}

.slick-dots li {
    min-width: 60px;
    max-width: 120px;
    width: auto;
}

.slick-dots li.slick-active {
    font-weight: bold;
}

.MuiButton-contained.MuiButton-containedPrimary {
    background-color: #bf9410;
    color: #f1f4f9;
    box-shadow: none;
}

    .MuiButton-contained.MuiButton-containedPrimary:hover {
        box-shadow: none;
        background-color: #cca940;
    }

.MuiButton-outlined.MuiButton-outlinedPrimary {
    border-color: #bf9410;
    color: #bf9410;
}

    .MuiButton-outlined.MuiButton-outlinedPrimary:hover {
        border-color: #bf9410;
    }

.fp-modal {
    width: 90vw;
    height: 90vh;
}

.fp-modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.fp-modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
}

.custom-hotspot {
    height: 50px;
    width: 50px;
    background: #f00;
}

div.custom-tooltip span {
    visibility: hidden;
    position: absolute;
    border-radius: 3px;
    background-color: #fff;
    color: #000;
    text-align: center;
    max-width: 200px;
    padding: 5px 10px;
    margin-left: -220px;
    cursor: default;
}

div.custom-tooltip:hover span {
    visibility: visible;
}

    div.custom-tooltip:hover span:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        bottom: -20px;
        left: -10px;
        margin: 0 50%;
    }

.pnlm-hotspot.pnlm-info {
    /*background-position: 0 -104px;*/
    background-position: 0 -130px;
}

.pnlm-hotspot.pnlm-scene {
    background-position: 0 -130px;
}

.project-modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.project-modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
}